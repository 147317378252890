import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localStorage
const user = JSON.parse(localStorage.getItem('user'))

// Set the initial state
const initialState = {
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Register User
export const register = createAsyncThunk('auth/register',async (user, thunkAPI) => {
    try {
        return await authService.register(user)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Generate New OTP
export const generateNewOTP = createAsyncThunk('auth/new-otp', async(userData, thunkAPI) => {
    try {
        return await authService.generateNewOTP(userData)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Verify Email
export const verifyEmail = createAsyncThunk('auth/verify-email', async(data, thunkAPI) => {
    try {
        return await authService.verifyEmail(data)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Verify Reset Token
export const verifyResetToken = createAsyncThunk('auth/verify-token', async(data, thunkAPI) => {
    try {
        return await authService.verifyResetToken(data)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Forgot Password
export const forgotPassword = createAsyncThunk('auth/forgot-password', async(email, thunkAPI) => {
    try {
        return await authService.forgotPassword(email)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Reset Password
export const resetPassword = createAsyncThunk('auth/reset-password', async(data, thunkAPI) => {
    try {
        return await authService.resetPassword(data)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Login User
export const login = createAsyncThunk('auth/login',async (user, thunkAPI) => {
    try {
        return await authService.login(user)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

// Logout user
export const logout = createAsyncThunk('auth/logout',async () => {
    await authService.logout()
})

// Update user state by pulling from the database
export const updateUser = createAsyncThunk('auth/getME', async (_, thunkAPI) => {
    try {
     // Get the user token from the State using thunkAPI (stored in localStorage)
     const token =thunkAPI.getState().auth.user.token
     // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
      return await authService.updateUser(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  })

// The slice
export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(register.pending, (state) => {
                state.isLoading = true
            })
            .addCase(register.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
                state.user = action.payload
            })
            .addCase(register.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null 
            })
            .addCase(generateNewOTP.pending, (state) => {
                state.isLoading = true
            })
            .addCase(generateNewOTP.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
            })
            .addCase(generateNewOTP.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(verifyEmail.pending, (state) => {
                state.isLoading = true
            })
            .addCase(verifyEmail.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
                state.user = action.payload
            })
            .addCase(verifyEmail.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload 
            })
            .addCase(verifyResetToken.pending, (state) => {
                state.isLoading = true
            })
            .addCase(verifyResetToken.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
            })
            .addCase(verifyResetToken.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(forgotPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
                state.message = action.payload.message
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(resetPassword.pending, (state) => {
                state.isLoading = true
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
                state.message = action.payload.message
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(login.pending, (state) => {
                state.isLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess =true
                state.user = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null 
            })
            .addCase(logout.fulfilled, (state) => {
                state.user = null
            })
            .addCase(updateUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateUser.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload
            })
            .addCase(updateUser.rejected, (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            })
    },
})

export const {reset} = authSlice.actions
export default authSlice.reducer