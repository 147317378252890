import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {getGroup, reset} from '../features/groups/groupSlice'
import {getGroupBets} from '../features/bets/betSlice'
import GroupForm from '../components/GroupForm'
import GroupMember from '../components/GroupMember'
import BetForm from '../components/BetForm'
import BetItem from '../components/BetItem'
import InvolvedItem from '../components/InvolvedItem'
import TransactionItem from '../components/TransactionItem'
import Spinner from '../components/Spinner'

function Group() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // Get the user data from the auth State
  const {user, isError, isLoading, message} = useSelector((state) => state.auth)
  // Get the group data from the local storage
  const groupInfo = JSON.parse(localStorage.getItem('groupInfo'))
  // Get the group data from the group State
  const {group} = useSelector((state) => state.groups)
  // Import the bets from the redux state
  const {bets} = useSelector((state) => state.bets)
  // Add state to control the visibility of the groupForm
  const [isGroupFormVisible, setGroupFormVisibility] = useState(false)
 // Add state to control the visibility of the groupForm
  const [isSettleFormVisible, setSettleFormVisibility] = useState(false)
  // State variable for the transactionsToSettle matrix
  const [transactionsToSettle, setTransactionsToSettle] = useState([])

    // State variables to manage the Active and History Tabs
    const [toggleInvolvedVsHistory, setInvolvedVsHistoryTab] = useState("active")
    const [activeClassName, setActiveClassName] = useState("form-group w-full p-2 border-b border-gray-300 bg-gray-300 font-bold")
    const [historyClassName, setHistoryClassName] = useState("form-group w-full p-2 border-b border-gray-300")
  
  
    const handleInvolvedVsHistoryToggle = (tab) => {
  
      if(tab === "active"){
          setActiveClassName("form-group w-full p-2 border-b border-gray-300 bg-gray-300 font-bold")
          setHistoryClassName("form-group w-full p-2 border-b border-gray-300")
          setInvolvedVsHistoryTab("active")
          console.log("Case 1")
      } else if (tab === "history"){
          setActiveClassName("form-group w-full p-2 border-b border-gray-300")
          setHistoryClassName("form-group w-full p-2 border-b border-gray-300 bg-gray-300 font-bold")
          setInvolvedVsHistoryTab("history")
          console.log("Case 2")
      }       
    }

  // Function to calculate the settling of accounts
  const settleBalances = () => {
    // Create two empty arrays for those who owe and those who are owed
    let oweMoney = []
    let areOwed = []
    
    // Iterate through the members and seperate those who owe (negative balance) from those who are owed
    group.members.forEach(member => {
      if(member.balance < 0){
        oweMoney.push({userId:member.userId, name: member.name, balance:member.balance})
      } else {
        areOwed.push({userId:member.userId, name: member.name, balance:member.balance})
      }
    })
    
    // Sort the two arrays from highest to lowest balance
    oweMoney.sort((a,b) => Math.abs(b.balance) - Math.abs(a.balance))
    areOwed.sort((a,b) => b.balance- a.balance)

    // Create an empty transactions to settle array and starting with the highest absolute balance start transferring betwen the two until one is zero and then move to the next.
    let b = 0
    let c = 0
    while (b<oweMoney.length && c < areOwed.length) {
      // Create a vairable called owe and owed that is at index of b and c
      const owe = oweMoney[b];
      const owed = areOwed[c];
      // find the lower of the two values and make that equal to the transaction amount
      const minBalance = Math.min(Math.abs(owe.balance), Math.abs(owed.balance));
      const transactionAmount = Math.abs(minBalance);
      // Push that transaction to the transactions to settle array
      transactionsToSettle.push({
        from: {userId:owe.userId,name:owe.name},
        to: {userId:owed.userId,name:owed.name},
        amount: transactionAmount
      });
      // Add that amount to the owe and subtract from the owed balances  
      owe.balance += transactionAmount;
      owed.balance -= transactionAmount;
      // If the new balance of owe is zero move to the next index
      if(owe.balance === 0){
          b++;
      }
      // If the new balance of owed is zero move to the new index
      if(owed.balance === 0){
          c++;
      }
    }

  //Pass the transacationsToSettle array to the state
    setTransactionsToSettle(transactionsToSettle)
  }

  // Function to toggle the GroupForm visibility
  const toggleGroupForm = () => {
    setGroupFormVisibility(!isGroupFormVisible)
  }

  // Function to handle a Settle up click
  const handleSettleUpClick = () => {
    setSettleFormVisibility(!isSettleFormVisible)
    settleBalances()
  }

  // Function that deploys on a given change 
  useEffect(() => {
    
      if(isError){
      console.log(message)
      }
      if(!user) {
        navigate('/login')
      }
      if(user != null && groupInfo){
        dispatch(getGroup(groupInfo._id))
        dispatch(getGroupBets({groupId:groupInfo._id}))
      } 
      if (!isSettleFormVisible) {
        setTransactionsToSettle([])
      }
      return () => {
        dispatch(reset())
      }
  }, [user, navigate, isError, message, dispatch, isSettleFormVisible])

  if(isLoading){
    return <Spinner />
  }

  if(group.length === 0){
    return<>
    <section className= "heading">
      <h1 className ="flex items-center justify-center">
        Join/Create a new group
      </h1>
      <p className="text-2xl">Search for an existing group, or create one here</p>
    </section>
    <section className="content shadow-md p-4">
      <h1 className="text-2xl pb-4">Create a new group</h1>
      <GroupForm isGroupFormVisible={isGroupFormVisible} toggleVisibility={toggleGroupForm}/>
    </section>
    </>
  }

  return <>
   {/* Heading section */}
   <section className= "heading">
      <h1 className ="flex items-center justify-center">
        {groupInfo.groupName}
      </h1>
      <p className="text-2xl">More information about this group </p>
    </section>
    {/* Section with group balances and options to settle up */}
    <section className="form shadow-md p-4">
      {group && group.members && group.members.length > 0 && !isSettleFormVisible ? (
      <>
        <h1 className="text-2xl pb-4">Group members and balances</h1>
        <div className="w-full pb-4 border-t border-gray-300 pt-2">
          {group.members.map((member)=> (
            <GroupMember key={group.members._id} member = {member}/>
          ))}
        </div>
        {isGroupFormVisible ? (<button className="btn bg-grey" style={{ display: 'block', margin: '0 auto 0.5rem'}} onClick={toggleGroupForm}>
         - 
        </button>) : (
        <button className="btn btn-block" style={{ display: 'block', margin: '0 auto 0.5rem'}} onClick={toggleGroupForm}>
        Add/Invite Member
        </button>
        )}
        {isGroupFormVisible && <GroupForm isGroupFormVisible={isGroupFormVisible} toggleVisibility={toggleGroupForm}/>}
        <button className="btn btn-block" onClick={handleSettleUpClick}>
          Settle up and cash out
        </button>
      </>
      ) : (
        isSettleFormVisible ? (
        <>
        <h1 className="text-2xl pb-4"> Here's how to settle up </h1>
        <div className="w-full pb-4 border-t border-gray-300 pt-2">
          {transactionsToSettle.map((transaction,index)=> (
            <TransactionItem key={index} transaction = {transaction}/>
          ))}
        </div>
        <button className="btn btn-block" onClick={handleSettleUpClick}>
        Done
        </button>
        </>
        ):(
        <h1 className="text-2xl pt-4 pb-4">You are not part of a group</h1>
        )
      )}
    </section>
    <BetForm/>    
    {/* Section that shows the bet information */}
    <section className="form shadow-md p-4">
      <div className='flex mb-4'>
        <label className={activeClassName} onClick={()=>handleInvolvedVsHistoryToggle("active")}>Active</label>
        <label className={historyClassName} onClick={()=>handleInvolvedVsHistoryToggle("history")}>History</label>
      </div>
      {toggleInvolvedVsHistory === "active" ? (
        <>
          <h1 className="text-2xl pb-4">Open Bets in {groupInfo.groupName} Group</h1>
            {bets.length > 0 ? (
              <div className="w-full">
                {bets.map((bet)=> (
                  <>
                    {!bet.settled ? (
                      <InvolvedItem key={bets._id} bet={bet}/>
                    ):(<></>)
                    }
                  </>
                ))}
              </div>
            ) : (<h3>You don't have any outstanding bets</h3>)}
        </>
      ):(
        <> 
          <h1 className="text-2xl pb-4">Bet History in {groupInfo.groupName} Group</h1>
          {bets.length > 0 ? (
            <div className="w-full">
              {bets.map((bet)=> (
                <>
                  {bet.settled ? (
                    <BetItem key={bets._id} bet={bet}/>
                  ):(<></>)
                  }
                </>
              ))}
              </div>
            ) : (<h3>You don't have any outstanding bets</h3>)}
        </>
      )}
    </section>
  </>  
}

export default Group