import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toast} from 'react-toastify'
import groupService from './groupService'


// Function that describes the initial state
const initialState = {
    group: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Create new Group
export const createGroup = createAsyncThunk('groups/create', async (groupInputs, thunkAPI) => {
    
    // Destructure the groupInputs that are sent from the form so we can add the member to the group that is created.
    const {groupName, user} = groupInputs

    // Convert the userEmail to make it as the user Id for now and give the member a balance of zero (for now, next step will be to do a get request using axios through a userSlice and userService)
    const members = [{userId:user._id, name:user.name, email:user.email, balance: 0, notifications:user.notifications}]

    const groupData = {groupName, members}

    try {
        
        // Call the createGroup function in the GroupService and pass in the group name and the user id that has been stored in localStorage
        const response = await groupService.createGroup(groupData)

        toast.success(`${user.name} has been added to the newly created group called: ${groupData.groupName} has been successfully created`)

        return response
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

// Get a groups' info by Id
export const getGroup = createAsyncThunk('groups/getGroup', async (groupId, thunkAPI) =>{
    try {
        
        // Call the getGroup function in the GroupService and pass in the group name and the user id that has been stored in localStorage
        return await groupService.getGroup(groupId)

    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

// Add a user to group that both adds the user to the group and add's the group id to the user
export const addMemberToGroup = createAsyncThunk('groups/addMemberToGroup', async (addMemberInputs, thunkAPI) => {
    
    const {groupId, userLookupInfo} = addMemberInputs
    const newMember = {userId:userLookupInfo._id, name:userLookupInfo.name, email:userLookupInfo.email, balance: 0, notifications:userLookupInfo.notifications}

    try {
        // Call the addUserToGroup function in the GroupService
        return await groupService.addMemberToGroup(groupId,newMember)

    } catch (error) {
         const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }

})

//Update the group member balances
export const updateMemberBalances = createAsyncThunk('groups/balances/updateMemberBalances', async (updateMemberInputs, thunkAPI) => {
    const {groupId, members} = updateMemberInputs

    try{
        //Call the updateMemberBalances service
        return await groupService.updateMemberBalances(groupId, members) 

    }catch(error){
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

// The GroupSlice
export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createGroup.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createGroup.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.group = action.payload
            })
            .addCase(createGroup.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getGroup.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getGroup.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.group = action.payload
            })
            .addCase(getGroup.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(addMemberToGroup.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addMemberToGroup.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.group = action.payload
            })
            .addCase(addMemberToGroup.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            }).addCase(updateMemberBalances.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateMemberBalances.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.group = action.payload
            })
            .addCase(updateMemberBalances.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const {reset} = groupSlice.actions
export default groupSlice.reducer