import { useNavigate } from 'react-router-dom'


function GroupItem({groupInfo}) {
  
  const navigate = useNavigate()

  const storeGroupOnClick = () =>{
    localStorage.setItem('groupInfo', JSON.stringify(groupInfo))

    navigate('/group')
  }
    
  return (
    <div className="form-group p-1 ml-1 mr-1 flex justify-between items-center border-b border-gray-300">
      <div className="w-full mb-2 flex justify-between items-center">
        <label className="font-bold">{groupInfo.groupName}</label>
        <label>My Balance: {groupInfo.balance < 0 ? <>-</> : <></>} ${Math.abs(groupInfo.balance)} </label>
        <button className="btn" onClick = {storeGroupOnClick}>View</button>
      </div>
    </div>
  )
}

export default GroupItem