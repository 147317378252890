import { useState } from 'react'
import {toast} from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { createBet } from '../features/bets/betSlice'
import IOUForm from './IOUForm'


function BetForm() {

    // Initialize the global state variables
    const { group } = useSelector((state) => state.groups)
    const { user } = useSelector((state) => state.auth)

    // Initialize the bettor as the username
    const bettor = { userId: user._id, name: user.name, email: user.email }

    // Initialize the local state variables
    const [isBetFormVisible, setBetFormVisibility] = useState(false)
    const [bettee, setSelectedBettees] = useState([])
    const [amount, setAmount] = useState()
    const [odds, setOdds] = useState(1)
    const [details, setDetails] = useState('')

    const today = new Date()
    today.setDate(today.getDate())
    const todayString = today.toISOString().split('T')[0]


    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    const yesterdayString = yesterday.toISOString().split('T')[0]

    const defaultDate = 'MM/DD/YY'

    const [by, setDate] = useState(defaultDate)

    // Initialise the useDispatch function as variable dispatch
    const dispatch = useDispatch()

    const toggleBetteeSelection = (member) => {
        if (bettee.some((item) => item.userId === member.userId)) {
            // If the member is alreay in bettee, remove item
            setSelectedBettees(bettee.filter((item) => item.userId !== member.userId))
        } else {
            setSelectedBettees([...bettee, { userId: member.userId, name: member.name, email:member.email }])
        }
    }

    //Function that toggles the add bet form when button is pressed
    const toggleBetForm = () => {
        setBetFormVisibility(!isBetFormVisible)
    }

    // Function that activates when the "Add Bet" button is clicked
    const onSubmit = async (e) => {
        e.preventDefault()

        const groupInfo = { groupId: group._id, groupName: group.groupName, members: group.members }
        
        let date

        if (by === defaultDate) {
            toast.error('Please Select a Date')
            setDate(defaultDate)
        } else {
            date = by
        }
      
        // Dispatch the createBet function and pass in the bet information as a single object
        dispatch(createBet({ groupInfo, bettor, bettee, amount, odds, details, date }))

        // Clear the form by setting all of the states to empty strings
        setSelectedBettees([])
        setAmount(0)
        setDetails('')
        setDate(defaultDate)
        toggleBetForm()
    }

    return (
        <section className='form shadow-md p-6'>
            <h1 className="text-2xl pb-4">Create Bets and IOUs</h1>
            <button
                className="btn btn-block bg-black"
                onClick={toggleBetForm}
            >Create a Bet</button>
            {isBetFormVisible ? (
                <form onSubmit={onSubmit}>
                    <h1 className="text-2xl">Bet Details</h1>
                    <div className="form-group">
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="text">Bettor:</label>
                            <input
                                type="text"
                                name='bettor'
                                id='bettor'
                                value={bettor.name}
                                className="ml-4"
                                disabled
                            />
                        </div>
                        <div className="pt-4 border-b border-gray-300">
                            <label htmlFor="text">Bets: (Select the people you want to bet against)</label>
                            <ul className="list-disc ml-4 mr-4 pb-4">
                                {group.members.length === 1 ? (
                                    <input
                                    type="text"
                                    name='bettor'
                                    id='bettor'
                                    value="Please add/invite members to the group to bet against"
                                    className="ml-4"
                                    disabled
                                    />
                                    ) : (
                                        group.members.map((member) => {
                                            if (member.userId === user._id) {
                                                return null
                                            }
                                            return (
                                                <li key={member.userId}
                                                    className="py-1 border-b border-gray-300 flex items-center"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={bettee.some((item) => item.userId === member.userId)}
                                                        onChange={() => toggleBetteeSelection(member)}
                                                    />
                                                    <span className="ml-4">{member.name}</span>
                                                </li>
                                            )
                                        })
                                    )
                                }
                            </ul>
                            <div className="flex items-center">
                                <label>Selected: </label>
                                <input
                                    htmlFor="text"
                                    type="text"
                                    name="bettee"
                                    id="bettee"
                                    value={bettee.map((item) => item.name).join(', ')}
                                    className="ml-4"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="number">Amount:</label>
                            <input
                                type="number"
                                name='amount'
                                id='amount'
                                value={amount === 0 ? '' : amount}
                                onChange={(e) => {
                                    const inputValue = parseInt(e.target.value);
                                    if (!isNaN(inputValue)) {
                                        setAmount(Math.max(0, inputValue));
                                    } else {
                                        // Handle invalid input, e.g., non-numeric characters
                                        setAmount('');
                                    }
                                }}
                                min='0'
                                className="ml-4"
                            /><label className="p-2">.00</label>
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="odds">At:</label>
                            <input
                                type="number"
                                name='odds'
                                id='odds'
                                value={odds}
                                onFocus={(e) => e.target.select()}
                                onChange={(e) => {
                                    let inputValue = e.target.value
                                    // Check if the input has more than three decimals
                                    const decimalIndex = inputValue.indexOf('.')
                                    if (decimalIndex !== -1 && inputValue.length - decimalIndex - 1 > 1) {
                                        let numberValue = parseFloat(inputValue)

                                        // Round the decimal portion to the nearest 0.5
                                        const roundedValue = Math.round(numberValue * 2) / 2;
                                        setOdds(roundedValue)
                                    } else {
                                        setOdds(inputValue)
                                    }}
                                }
                                step='0.5'
                                min = '1'
                                max = '99.5'
                                className="ml-4 text-right"
                                style= {{ width: '8ch'}}
                            /><label className="pl-2 pb-2 items-center ">:<span className="ml-3 p-2.5 rounded-md bg-gray-100 border border-gray-200">1</span> odds</label>
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="text">Details:</label>
                            <input
                                type="text"
                                name='details'
                                id='details'
                                value={details}
                                onChange={(e) => setDetails(e.target.value)}
                                className="ml-4"
                            />
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="text">By:</label>
                            <input
                                type="date"
                                name='by'
                                id='by'
                                value={by}
                                onChange={(e) => setDate(e.target.value)}
                                min={todayString}
                                className="ml-4"
                            />
                        </div>
                    </div>
                    <div className="pt-4 form-group">
                        <button className="btn btn-block bg-black" type="submit">Add Bet</button>
                    </div>
                </form>) : (<></>)}
                <IOUForm/>
        </section>
    )
}

export default BetForm