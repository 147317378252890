import axios from 'axios'

const API_URL = 'api/bets/'

// Create new bet
const createBet = async (betData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL, betData, config)

    return response.data
}

// Create new IOU
const createIOU = async (IOUData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.post(API_URL + 'IOU', IOUData, config)

    return response.data
}

// Get the user's bets
const getUserBets = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.get(API_URL, config)

    return response.data
}

// Get a Group's bets
const getGroupBets = async (groupId) => {
    
    const response = await axios.get(API_URL + 'group', {params: groupId})
        
    return response.data
}

// Get bets that a user is involved in
const getInvolvedBets = async (userId) => {
    
    const response = await axios.get(API_URL + 'involved', {params: userId})
        
    return response.data
}

// Update a Bet's status
const updateBetStatus = async (updateBetStatusData, token) => {
    const {betId, status} = updateBetStatusData
        
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.put(API_URL + 'status/'+ betId, {status}, config)

    return response.data

}

// Settle a bet
const settleBet = async (settleData, token) => {
    const {betId, winner} = settleData
        
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.put(API_URL + 'settle/'+ betId, {winner}, config)

    return response.data

}


// Delete a user's bet
const deleteBet = async (betId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    const response = await axios.delete(API_URL + betId, config)

    return response.data
}

const betService = {
    createBet,
    createIOU,
    getUserBets,
    getGroupBets,
    getInvolvedBets,
    updateBetStatus,
    settleBet,
    deleteBet,
}

export default betService
