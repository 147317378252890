import {FaSignInAlt, FaSignOutAlt, FaUser} from 'react-icons/fa'
import {Link, useNavigate} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {logout, reset} from '../features/auth/authSlice'

function Header() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.auth)

    const onLogout = () => {
        dispatch(logout())
        navigate('/')
        dispatch(reset())
    }

  return (
    <header className= 'header'>
        <div className='logo' style={{maxWidth: '30%', height:'auto'}}>
            <Link to='/' className='text-2xl font-bold'> 
                <img src="squirrelBetsHorizontal.png" alt="logo" style={{maxWidth: '100%', height:'auto'}}/>
            </Link>
        </div>
        <ul>
            {user ? (
                <li>
                <button className='btn bg-black' onClick={onLogout}>
                    <FaSignOutAlt /> Logout
                </button>
                </li>
            ):(<>
                <li>
                <Link to='/login'>
                    <FaSignInAlt /> Login
                </Link>
                </li>
                <li>
                <Link to='/register'>
                    <FaUser /> Register
                </Link>
                </li>
            </>)}
            
        </ul>
    </header>
  )
}

export default Header