import axios from 'axios'

const API_URL = '/api/users/'

// Register user
const register = async (userData) => {
    const response = await axios.post(API_URL, userData)

    // If the response contains data
    if(response.data && response.data.token) {
        // Convert it to a JSON string and store in the local storage
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Generate new OTP
const generateNewOTP = async (userData) => {
    try{
        const response = await axios.post(API_URL + 'new-otp', userData)
        return response.data
    } catch (error){
        throw error
    }
}

// Verify email
const verifyEmail = async(data) => {
    try{
        const response = await axios.post(API_URL + 'verify-email', data)
        return response.data
    } catch (error) {
        throw error
    }
}

// Verify reset password token
const verifyResetToken = async (data) => {
    const {token, id} = data
    
    try{
        const response = await axios.get(API_URL + 'verify-token', {
            params: {
                token: token,
                id: id 
            }
        })
        return response.data
    } catch (error){
        throw error   
    }
}

// Verify forgot password
const forgotPassword = async (email) => {
    
    
    try{
        const response = await axios.post(API_URL+'forgot-password',email)
        return response.data
    } catch (error){
        throw error   
    }
}

// Verify reset password token
const resetPassword = async (data) => {
    const {token, id, password} = data
    try{
        const response = await axios.post(`${API_URL}reset-password?token=${token}&id=${id}`,{password:password})
        return response.data
    } catch (error){
        throw error   
    }
}


// Login user
const login = async (userData) => {
    const response = await axios.post(API_URL + 'login', userData)

    // If the response contains data
    if(response.data) {
        // Convert it to a JSON string and store in the local storage
        localStorage.setItem('user', JSON.stringify(response.data))
    }

    return response.data
}

// Logout user 
const logout = () => {
    localStorage.clear()
}

// Update user state from the database.
const updateUser = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }
    
    const response = await axios.get(API_URL + 'me',config)

     // If the response contains data
     if(response.data) {

        const user = response.data
        user.token = token
        // Convert it to a JSON string and store in the local storage
        localStorage.setItem('user', JSON.stringify(user))
    }

    return response.data
}

const authService = {
    register,
    generateNewOTP,
    verifyEmail,
    verifyResetToken,
    forgotPassword,
    resetPassword,
    logout,
    login,
    updateUser
}

export default authService