import axios from "axios"

const API_URL = 'api/groups/'

//create new group
const createGroup = async(groupData) => {
          
   const response = await axios.post(API_URL, groupData)

   if(response.data){
   // Convert it to a JSON string and store in the local storage
   localStorage.setItem('groupInfo', JSON.stringify(response.data))
   }

   return response.data
}

//Get a group's information
const getGroup = async(groupId) => {
          
    const response = await axios.get(API_URL + groupId)
 
    return response.data
 }

// Add a member to a group
const addMemberToGroup = async(groupId, newMember) => {
          
    const response = await axios.put(API_URL + groupId, newMember)
 
    return response.data
 }

 // Update group member balances
const updateMemberBalances = async(groupId, members) => {
          
    const response = await axios.put(API_URL + 'balances/' + groupId, members)
 
    return response.data
 }

const groupService = {
    createGroup,
    getGroup,
    addMemberToGroup,
    updateMemberBalances,
}

export default groupService