import {FaTrash} from 'react-icons/fa'
import {toast} from 'react-toastify'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { updateUser } from '../features/auth/authSlice'
import {deleteBet, settleBet} from '../features/bets/betSlice'
import {updateInvolvedBetStatus} from '../features/bets/involvedSlice'
import {getGroup, updateMemberBalances} from '../features/groups/groupSlice'
import { updateUserGroupBalance } from '../features/users/userSlice'

function formatDate(date){
  // Create a new Date object from the provided date string.
  const betDate = new Date(date)

  // Get the date components (year, month, day).
  const year = betDate.getFullYear()
  const month = (betDate.getMonth() +1).toString().padStart(2,'0')
  const day = (betDate.getDate()+1).toString().padStart(2,'0')

  // Combine the components into the formatted date string.
  return `${month}/${day}/${year}`
}

function InvolvedItem({bet}) {

  const {group} = useSelector((state)=>state.groups)
  const {user} = useSelector((state)=>state.auth)
  const [isSettleFormVisible, setSettleFormVisibility] = useState(false)
  const [betWinner, setBetWinner] = useState('')
  const [showStatuses, setShowStatuses] = useState(false) // Overlay on Click
  const dispatch = useDispatch()

  let thisUserId
  user ? thisUserId = user._id : thisUserId = 1234
  const date = bet.by ? formatDate(bet.by): "no date set"

 
  //Create an array of participants in the bet by combinging both the bettors and bettees 
  const participants = [bet.bettor, ...bet.bettee]

  // Variables to track the status of the bet and to show the headline differently based on the status
  let status = "omitted"
  let buttonName
  let declineButton = false
  let disabled = false
  let participantUserIds = [] //empty array to store the participantUserIds
  const betDate = new Date(bet.by)
  const today = new Date()
  const yesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1)

  // Function to toggle overlay visibility
  const toggleStatuses = () => {
    setShowStatuses(!showStatuses)
  }
  
  // Cycle through the participants array and read the status of the logged in user also populate the participantUserIds array
  if(user){
    participants.forEach((participant) => {
        if(participant.userId === user._id){
            status = participant.status
        }
        participantUserIds.push(participant.userId)
    })
  }

  // Change the button name depending on the status (invited, accepted, declined, closed, settled, contested)
  switch (status) {
    case 'invited':
        buttonName = 'Accept'
        declineButton = true
        // If the bet is expired, disable the button and change the status to closed
        if(yesterday > betDate || bet.bettor.status === "closed"){
            disabled = true
            status = 'closed'
            declineButton = false
        }
        break
    case 'accepted':
        // If the bet is expired, update the status to closed in state and DB, else disable the button
        if(yesterday > betDate){
           dispatch(updateInvolvedBetStatus({betId:bet._id,status:'closed'}))
           buttonName = 'Settle'
           status = 'closed'
        } else if (thisUserId === bet.bettor.userId){
            buttonName = 'Close'
            disabled = false
        } else {
            buttonName = 'Accept'
            disabled = true
        }
        break
    case 'declined':
        buttonName = 'Accept'
        disabled = true
        break
    case 'closed':
        buttonName = 'Settle'
        break
    case 'settled':
        buttonName = 'Contest'
        break
    case 'contested':
        buttonName = 'Contest'
        disabled = true
        break
    default:
        buttonName = 'Settle'
  }


  //Function that toggles the settle button
  const handleButtonClick = () =>{
    console.log(status) //For some reason this makes the case where the button is named "close" work. Don't remove
    switch(status) {
        case 'invited':
            dispatch(updateInvolvedBetStatus({betId:bet._id,status:'accepted'}))
            break
        case 'accepted':
            if(bet.bettor.userId === user._id && bet.bettee.some((bettee) => bettee.status ==="accepted")){
                dispatch(updateInvolvedBetStatus({betId:bet._id,status:'closed'}))
            } else {
                toast.error("You cannot close the bet until someone has accepted it")
            }
            break
        case 'closed':
            setSettleFormVisibility(!isSettleFormVisible)
            break
        case 'settled':
            dispatch(updateInvolvedBetStatus({betId:bet._id,status:'contested'}))
            break
        case 'contested':
            // disable the contest button
            break
        default:
            setSettleFormVisibility(!isSettleFormVisible)
    }
  }

  const handleSettleBet = async (selectedWinner) => {
    if (!selectedWinner){
        toast.error('Please select a winner')
        return
    }

    // Create an empty array to store the participants in the bet who have an accepted or closed status
    let acceptedParticipantUserIds = []

    // Fill the accepted participants array by cycling through the participants and checking their status
    participants.forEach(participant => {
        if(participant.status === 'accepted' || participant.status === 'closed'){
            acceptedParticipantUserIds.push(participant.userId)
        } 
    })
  
    // Define the settleData as the bet Id and the selected winner
    const settleData = {betId:bet._id, winner:selectedWinner}

    //Create a mutable groupMembers array. 
    let groupMembers

    if (group.members) {
        // If the group exists, map members to groupMembers
        groupMembers = group.members.map(member => ({ ...member }));
    } else {
        // If the group doesn't exist, dispatch an action to get it
        await dispatch(getGroup(bet.group.groupId))
            .then((action) => {
                // Once the group is retrieved, you can access members
                const retrievedGroup = action.payload;
                groupMembers = retrievedGroup.members.map(member => ({ ...member }));
            })
            .catch((error) => {
                // Handle error if needed
                console.error('Error fetching group:', error);
            });
    }

    // Find the index of the groupmember who matches the bet winner userId
    const winnerIndex = groupMembers.findIndex((member) => member.userId === selectedWinner.userId)
    
    // If an index was found, update the winners' balance with each loser paying.
    if(winnerIndex !== -1) {
      groupMembers[winnerIndex].balance += (acceptedParticipantUserIds.length-1)*bet.amount
    }

    // Update the loser's balances.

    acceptedParticipantUserIds.forEach(userId => {
      if (userId!==selectedWinner.userId){
        const loserIndex = groupMembers.findIndex(member => member.userId === userId)
        if (loserIndex !== -1){
        // If an index was found, update the losers' balance
        groupMembers[loserIndex].balance -= bet.amount
        }
      }
    })

    // Update each of the members balances in their user group. 
    const updateMemberUserBalances = groupMembers.map(async (member)=>{
        const memberGroupInfo = {userId:member.userId, groupId:bet.group.groupId, balance:member.balance}

        await dispatch(updateUserGroupBalance(memberGroupInfo))
    })

    await dispatch(settleBet(settleData))
    await dispatch(updateMemberBalances({groupId: bet.group.groupId, members:groupMembers}))  
    await Promise.all(updateMemberUserBalances)
    await dispatch(updateUser())

    setSettleFormVisibility(false) 
  }

  return (
    <>
    {/* Check to see the bet is still not settled and show if it is not */}
    {bet.settled === true ? (<></>) : (
        <>
            <div className="form-group w-full p-1 border-b border-gray-300">
            {/* Are we on the group page? if not show the name of the group if so, do not show the group name in the bet details */}
            <div className="flex">
                {group.length === 0 ? (
                <>
                    <label className="font-bold">{bet.group.groupName} </label>
                </>):(<></>)}
                    <label className={status}> {status}</label>
            </div>
            {/* Display the bet details */}
            <div className="w-full mb-2 flex items-start justify-between text-sm">
                <label
                    onClick={toggleStatuses}
                >
                    {bet.bettor?.name} bets {bet.bettee?.map((item)=>item.name).join(', ')}, |${bet.amount}| at {bet.odds}:1 odds, details: {bet.details}, by: {' '}{date}

                    {showStatuses && (
                        <div
                        className="mt-2 top-0 left-1 w-full h-full flex items-center justify-left"
                        >
                            <ul className="list-disc ml-4 mr-4">
                                {participants.map((participant)=>(
                                    <li 
                                        key={participant.name}
                                        className="bg-gray-100 m-1 pl-2 rounded-md"
                                    >
                                        {participant.name}:<span className={`${participant.status}-text `}>{participant.status} </span>  
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </label>

                {bet.settled ? (
                <></>
                ):(
                    <>
                    {/* If the user is not a participant in the bet disable the button (for Group page case). Else, have the button enabled by default and only disable based on status cases */}
                    {!participantUserIds.includes(thisUserId) ? 
                    (
                        <button className = "btn ml-1 mr-1" disabled >
                        {buttonName}
                        </button>
                    ):(
                        <button className = "btn ml-1 mr-1" disabled={disabled} onClick={handleButtonClick}>
                            {buttonName}
                        </button>
                    )}
                    </>
                )}
                {thisUserId === bet.bettor?.userId ? (
                    <button onClick={() => dispatch(deleteBet(bet._id))} className="btn">
                        <FaTrash/>
                    </button>
                    ):(declineButton === true ? (
                        <button 
                            className = "btn ml-1 mr-1"
                            onClick={() => dispatch(updateInvolvedBetStatus({betId:bet._id,status:'declined'}))}
                        >
                        Decline
                        </button>
                        ) : (
                            <button onClick={() => dispatch(deleteBet(bet._id))} disabled={true} className="btn bg-gray">
                            <FaTrash/>
                            </button>
                        )
                )}    
            </div>

            {bet.settled ? (
                <>
                <label className="font-bold">{bet.winner.name} won</label>
                </>
            ):(<></>)}
            {/* Toggle the settle form if the settle button is clicked */}
            {isSettleFormVisible ? (
                <>
                <div className="w-full mb-2 flex items-center justify-between">
                    <select
                        className = "items-center"
                        name = "selectWinner"
                        id = "selectWinner"
                        value={betWinner}
                        onChange={(e) => setBetWinner(e.target.value)}
                    >
                        <option value="">Who won?</option>
                        <option value={bet.bettor.name}>{bet.bettor.name}</option>
                        {bet.bettee.map((item)=> (
                        <option key={item.userId} value={item.name}>
                            {item.name}
                        </option>
                        ))}
                    </select>
                    {/* Pass the userId and name of whoever is selected based on if  they are a bettor or a bettee */}
                    <button 
                    className = "btn ml-1 mr-1 mb-2" 
                    onClick={() => {
                        if (betWinner === bet.bettor.name) {
                        handleSettleBet({ userId: bet.bettor.userId, name: bet.bettor.name });
                        } else {
                        const selectedBettee = bet.bettee.find((item) => item.name === betWinner);
                        if (selectedBettee) {
                            handleSettleBet({ userId: selectedBettee.userId, name: selectedBettee.name });
                        } else {
                            toast.error('Invalid winner selection');
                        }
                        }
                    }}
                    >
                    Confirm
                    </button>
                </div>
                </>):(
                <></>
                )}
            </div>
        </>)}
    </>
  )
}

export default InvolvedItem