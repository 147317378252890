import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import betService from './betService'

// Function that describes the initial state
const initialState = {
    involved: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Get bets the user is involved in
export const getInvolvedBets = createAsyncThunk('bets/getInvolvedBets', async (userId, thunkAPI) => {
    try {
        
        return await betService.getInvolvedBets(userId)
    
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

//Update involvedBetStatus
export const updateInvolvedBetStatus = createAsyncThunk('bets/updateInvolvedBetStatus', async (updateBetStatusData, thunkAPI) => {
    try {
        // Get the user token from the State using thunkAPI (stored in localStorage)
        const token =thunkAPI.getState().auth.user.token
        // Call the updateBetStatus function
        return await betService.updateBetStatus(updateBetStatusData,token)
    
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

// The involvedSlice
export const involvedSlice = createSlice({
    name: 'involved',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getInvolvedBets.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getInvolvedBets.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.involved = action.payload
            })
            .addCase(getInvolvedBets.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateInvolvedBetStatus.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateInvolvedBetStatus.fulfilled, (state, action) => {
                const updatedBet = action.payload
                const index = state.involved.findIndex(involved => involved._id === updatedBet._id)

                if (index !== -1) {
                    state.involved[index] = updatedBet
                }
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(updateInvolvedBetStatus.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const {reset} = involvedSlice.actions
export default involvedSlice.reducer