import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {updateMemberBalances} from '../features/groups/groupSlice'
import { updateUserGroupBalance} from '../features/users/userSlice'
import { updateUser } from '../features/auth/authSlice'

function TransactionItem({transaction}) {
  const dispatch = useDispatch()
  const {group} = useSelector((state)=>state.groups)
  const {user} = useSelector((state)=>state.auth)
  const [isPaid, setIsPaid] = useState(false)
   
  let disabled = true
  
  if(transaction.to.userId === user._id){
    disabled = false
  }
  
  const handlePaidClick = async () =>{

    //Acces the group members from the redux state and store in a new array called groupMembers
    const groupMembers = group ? group.members.map(member => ({...member})): []
    
    groupMembers.forEach((member)=> {
      if(member.userId===transaction.from.userId){
        member.balance += transaction.amount
      }
      if(member.userId===transaction.to.userId){
        member.balance -= transaction.amount
      }
    })

    // Update each of the members balances in their user group. 
    const updateMemberUserBalances = groupMembers.map(async (member)=>{
      const memberGroupInfo = {userId:member.userId, groupId:group._id, balance:member.balance}

      await dispatch(updateUserGroupBalance(memberGroupInfo))
    })
    
    await Promise.all(updateMemberUserBalances)
    await dispatch(updateMemberBalances({groupId:group._id, members:groupMembers}))
    await dispatch(updateUser())

    setIsPaid(true)
  }
  
  return isPaid ? null : (
    <div className="form-group p-1 ml-1 mr-1 flex justify-between items-center border-b border-gray-300">
      <h2 className="font-bold mb-2">{transaction.from.name} owes {transaction.to.name} ${transaction.amount}</h2>
      <button className="btn" onClick={handlePaidClick} disabled ={disabled}>Paid</button>        
      </div>
  )
}
  
  export default TransactionItem