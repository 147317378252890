import {useState, useEffect} from 'react'
import {FaKey} from 'react-icons/fa'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {forgotPassword, reset} from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function ForgotPassword() {
    const [formData, setFormData] = useState({
        email: '',
        confirm: '',
    })
    const [successful, setSuccessful] = useState(false)
    const {email, confirm} = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Destructure the useSelector to manage the different states
    const {user, isLoading, isError, isSuccess, message} = useSelector
    (
        (state) => state.auth
    )

    // The use effect function
    useEffect(()=>{
        if(isError) {
            toast.error(message)
        }
        if(isSuccess){
            setSuccessful(true)
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange=(e) =>{
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onSubmit = (e) => {
        e.preventDefault()

        if(email === '' || confirm === ''){
            toast.error('Please input and confirm your email')
        }
        else if(email !== confirm){
            toast.error('Emails do not match')
            setFormData({email:'', confirm:''})
        } else {
            dispatch(forgotPassword({email: email}))
            setFormData({email:'', confirm:''})
            setSuccessful(true)
        }
    }

    if(isLoading){
        return <Spinner />
    }

    if(successful === true){
        return (
            <>
                <section className="heading">
                    <h1 className ="flex items-center justify-center">
                        <span className="pr-2">
                            <FaKey/>
                        </span> 
                        Forgot Password         
                    </h1>
                    <p className="text-2xl">A reset link was sent to your inbox</p>
            </section>
            </>
        )
    }
  
    return (
    <>
    <section className="heading">
        <h1 className ="flex items-center justify-center">
            <span className="pr-2">
                <FaKey/>
            </span> 
            Forgot Password         
        </h1>
        <p className="text-2xl">Please provide your email</p>
    </section>
    <section className="form">
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <input 
                type="email" 
                className="form-control" 
                id="email" 
                name="email" 
                value={email} 
                placeholder='Enter your email' 
                onChange={onChange} 
                />
            </div> 
            <div className="form-group">
                <input 
                type="email" 
                className="form-control" 
                id="confirm" 
                name="confirm" 
                value={confirm} 
                placeholder='Confirm your email' 
                onChange={onChange} 
                />
            </div>
            <div className="form-group">
                <button type="submit" className="btn btn-block bg-black">
                    Submit
                </button>
            </div>
        </form>
    </section>
    </>
    )
}

export default ForgotPassword