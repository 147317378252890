// @babel/plugin-proposal-private-property-in-object
import {toast} from 'react-toastify'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import Spinner from '../components/Spinner'
import { getUserBets, reset } from '../features/bets/betSlice'
import { getInvolvedBets } from '../features/bets/involvedSlice'
import InvolvedItem from '../components/InvolvedItem'
import HistoryItem from '../components/HistoryItem'
import GroupItem from '../components/GroupItem'


function Dashboard() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // Get the user data from the auth State
  const { user } = useSelector((state) => state.auth)
  // Get the bets the user is involved with from the involved state
  const { involved } = useSelector((state)=> state.involved)
  // Get the user's bets from the bets state
  const { isLoading, isError, message } = useSelector((state) => state.bets)

  // State variables to manage the Active and History Tabs
  const [toggleInvolvedVsHistory, setInvolvedVsHistoryTab] = useState("active")
  const [activeClassName, setActiveClassName] = useState("form-group w-full p-2 border-b border-gray-300 bg-gray-300 font-bold")
  const [historyClassName, setHistoryClassName] = useState("form-group w-full p-2 border-b border-gray-300")


  const handleInvolvedVsHistoryToggle = (tab) => {

    if(tab === "active"){
        setActiveClassName("form-group w-full p-2 border-b border-gray-300 bg-gray-300 font-bold")
        setHistoryClassName("form-group w-full p-2 border-b border-gray-300")
        setInvolvedVsHistoryTab("active")
        console.log("Case 1")
    } else if (tab === "history"){
        setActiveClassName("form-group w-full p-2 border-b border-gray-300")
        setHistoryClassName("form-group w-full p-2 border-b border-gray-300 bg-gray-300 font-bold")
        setInvolvedVsHistoryTab("history")
        console.log("Case 2")
    }       
  }

  const createGroup = () => {
    localStorage.removeItem('groupInfo')
    navigate('/group')
  }

  useEffect(() => {  
    if (isError) {
      console.log(message)
    }
    if (user != null){
        if(user.verified === false){
        toast.error('Please verify your email to login')
        navigate('/verify')
        }else{
          dispatch(getUserBets())
          dispatch(getInvolvedBets({userId: user._id}))
        }
    }else{
      navigate('/login')
    }
    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return <>
    <section className="heading">
      <h1 className="flex items-center justify-center">
        Welcome {user && user.name}
      </h1>
      <p className="text-2xl">Keep track of your groups and outstanding bets</p>
    </section>

    <section className="form shadow-md p-4">
      <h1 className="text-2xl pb-4">Your Betting Groups</h1>
      {user && user.groups && user.groups.length > 0 ? (
        <div className="w-full pb-4">
          {user.groups.map((groupInfo) => (
            <GroupItem key={user.groups._id} groupInfo={groupInfo} />
          ))}
        </div>
      ) : (<h3>Join or Create a Group of Friends to Place Bets With</h3>)}
      <button className="btn btn-block" onClick={createGroup}>Join/Create a Group</button>
    </section>
    {/* Bet invitations Section */}
    <section className="form shadow-md p-4">
      <div className='flex mb-4'>
        <label className={activeClassName} onClick={()=>handleInvolvedVsHistoryToggle("active")}>Active</label>
        <label className={historyClassName} onClick={()=>handleInvolvedVsHistoryToggle("history")}>History</label>
      </div>
      {toggleInvolvedVsHistory === "active" ? (
        <>
          <h1 className="text-2xl pb-4">Bets you're involved with </h1>
          {involved.length > 0 ? (
            <div className="w-full">
              {involved.map((bet) => (
                <InvolvedItem key={involved._id} bet={bet} />
              ))}
            </div>
          ) : (<h3>You aren't a part of any bets</h3>)}
        </>
        ):(
        <>
          <h1 className="text-2xl pb-4">Bet History</h1>
          {involved.length > 0 ? (
            <div className="w-full">
              {involved.map((bet) => (
                <HistoryItem key={involved._id} bet={bet} />
              ))}
            </div>
          ) : (<h3>You don't have any previous bets</h3>)}
        </>
        )}
    </section>
  </>
}

export default Dashboard