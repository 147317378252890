import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {toast} from 'react-toastify'
import userService from './userService'

// Function that describes the initial state
const initialState = {
    user: '',
    group: '',
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Find a user's Id by searching their email
export const getUserId = createAsyncThunk('users/getUserId', async (info, thunkAPI) => {
       
    try {
        
        // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
        return await userService.getUserId(info)

    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

export const addGroupToUser = createAsyncThunk('users/addGroupToUser', async (addGroupToUser, thunkAPI) => {
    
    try {
        
        // Call the addGroupToUser function in the userService and pass in the group info and userId
        const response = await userService.addGroupToUser(addGroupToUser)

        toast.success('The group has been added to the users profile')

        return response

    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

export const updateUserGroupBalance = createAsyncThunk('users/updateUserGroupBalance', async (memberGroupInfo, thunkAPI) => {
    
    try {
        
        // Call the addGroupToUser function in the userService and pass in the group info and userId
        const response = await userService.updateUserGroupBalance(memberGroupInfo)

        return response

    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

// The userSlice
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserId.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserId.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(getUserId.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(addGroupToUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addGroupToUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.group = action.payload
            })
            .addCase(addGroupToUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateUserGroupBalance.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateUserGroupBalance.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(updateUserGroupBalance.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const {reset} = userSlice.actions
export default userSlice.reducer