import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import betService from './betService'


// Function that describes the initial state
const initialState = {
    IOUs: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Create new bet
export const createIOU = createAsyncThunk('bets/IOU/create', async (IOUData, thunkAPI) => {
    try {
        // Get the user token from the State using thunkAPI (stored in localStorage)
        const token =thunkAPI.getState().auth.user.token
        // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
        const response =  await betService.createIOU(IOUData, token)
        
        toast.success('Your IOU was successfuly created')
        return response
        
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})


// The IOUSlice
export const IOUSlice = createSlice({
    name: 'IOU',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createIOU.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createIOU.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.IOUs.push(action.payload)
            })
            .addCase(createIOU.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const {reset} = IOUSlice.actions
export default IOUSlice.reducer