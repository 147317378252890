import {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import queryString from 'query-string'
import {toast} from 'react-toastify'
import Spinner from '../components/Spinner'
import InvolvedItem from '../components/InvolvedItem'
import { getInvolvedBets, reset } from '../features/bets/involvedSlice'
import {logout} from '../features/auth/authSlice'

function Accept() {
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    
    const {betId, memberId} = queryString.parse(location.search)

    // Get the user data from the auth State
    const { user } = useSelector((state) => state.auth)
    // Get involved bets from the involved State
    const { involved, isLoading, isError, message} = useSelector((state)=> state.involved)

    // Navigate to Login Page after succesful password change
    const handleLogin = () => {
      navigate(`/login?betId=${betId}&memberId=${memberId}`) 
    }

    const handleLogout = () => {
      dispatch(logout())
      navigate(`/login?betId=${betId}&memberId=${memberId}`) 
      dispatch(reset())
    }

    const goToDashboard = () => {
      navigate('/')
    }

    useEffect(() => {
        if (isError) {
          console.error(message)
        }  
        if (user){
            if(!user.verified){
            toast.error('Please verify your email')
            navigate('/verify')
            }else{
              dispatch(getInvolvedBets({userId: user._id}))
              .unwrap()
              .finally(() => setLoading(false))
            }
        }else{
          handleLogin()
        }
        return () => {
          dispatch(reset())
        }
      }, [user, navigate, isError, message, dispatch])
    
      if ( loading || isLoading ) {
        return <Spinner />
      }

      // If the logged in user isn't the same as the member who received the email ask them to login to the correct
      if ( user._id !== memberId){
        return(
          <>
            <section className="heading">
              <h1 className="text-center">
              Please Login to The Correct Account
              </h1>
            </section>
            <section className="heading form shadow-md p-4">
                <div className="form-group p-4">
                    <p className="text-center">
                    You are not logged into the correct account. Please log into the account associated with the email this link was sent to. 
                    </p>
                    <button 
                            type="button"
                            onClick={handleLogout} 
                            className="mt-4 btn btn-block bg-black" 
                            > Login to Correct Account
                    </button>
                </div> 
            </section>
          </>
        )
      }
      // Find the bet with the provided betId
      const bet = involved.find(bet => bet._id === betId)
      if(!bet){
        return(
          <>
            <section className="heading">
              <h1 className="text-center">
              Bet Not Found
              </h1>
            </section>
            <section className="heading form shadow-md p-4">
                <div className="flex p-4">
                    <p className="text-center">
                    There was an issue finding the bet. Please login and check the dashboard or contact the Bettor. 
                    </p>
                    <button 
                            type="button"
                            onClick={handleLogin} 
                            className="mt-4 btn btn-block bg-black" 
                            > Login
                    </button>
                </div> 
            </section>
          </>
        )
      } 
      return (
        <section className="form shadow-md p-4">
          <div className='flex mb-4'>
            <div className="w-full">
              <InvolvedItem bet={bet}/>
              <button 
                type="submit"
                onClick={goToDashboard} 
                className="mt-4 btn btn-block bg-black" 
                > Go to Dashboard
              </button>
            </div>
          </div>
        </section>
      )
}

export default Accept