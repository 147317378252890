import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Header from './components/Header'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Register from './pages/Register'
import VerifyEmail from './pages/VerifyEmail'
import Group from './pages/Group'
import PasswordReset from './pages/PasswordReset'
import ForgotPassword from './pages/ForgotPassword'
import Accept from './pages/Accept'
import Decline from './pages/Decline'

function App() {
  return (
   <>
   <Router>
    <div className = "container">
      <Header />
        <Routes>
          <Route path='/' element={<Dashboard/>}/>
          <Route path='/forgot-password' element={<ForgotPassword/>}/>
          <Route path='/reset-password' element={<PasswordReset/>}/>
          <Route path='/group' element={<Group/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/register' element={<Register/>}/>
          <Route path='/verify' element={<VerifyEmail/>}/>
          <Route path='/accept' element={<Accept/>}/>
          <Route path='/decline' element={<Decline/>}/>
        </Routes>
    </div>
   </Router>
   <ToastContainer/>
  </>
  );
}

export default App;
