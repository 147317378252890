import axios from "axios"

const API_URL = 'api/users/'

//Get a User's Name and Id based on their email
const getUserId = async(info) => {

    const {email, inviteUserName, inviteEmail, groupId } = info

    console.log(info)

    const response = await axios.get(API_URL + 'lookup', {
    params: {
        email,
        inviteUserName,
        inviteEmail,
        groupId
    }})
    
    if(response.data) {
        // Convert it to a JSON string and store in the local storage
        localStorage.setItem('userLookupInfo', JSON.stringify(response.data))
    }
    return response.data
}

//Add Group to User
const addGroupToUser = async(addGroupToUser) => {

    const {userId , groupInfo } = addGroupToUser

    const response = await axios.put(API_URL + userId, groupInfo)
    
    return response.data
}

//Update User Group Balance
const updateUserGroupBalance = async(memberGroupInfo) => {

    const {userId, groupId , balance} = memberGroupInfo

    const response = await axios.put(API_URL + 'balance/' + userId, {_id:groupId, balance:balance})
    
    return response.data
}

const userService = {
    getUserId,
    addGroupToUser,
    updateUserGroupBalance
}

export default userService