import {FaTrash} from 'react-icons/fa'
import {toast} from 'react-toastify'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {deleteBet, settleBet} from '../features/bets/betSlice'
import {updateMemberBalances} from '../features/groups/groupSlice'

function formatDate(date){
  // Create a new Date object from the provided date string
  const betDate = new Date(date)

  // Get the date components (year, month, day)
  const year = betDate.getFullYear()
  const month = (betDate.getMonth() +1).toString().padStart(2,'0')
  const day = (betDate.getDate()+1).toString().padStart(2,'0')

  // Combine the components into the formatted date string
  return `${month}/${day}/${year}`
}

function BetItem({bet}) {

  const {group} = useSelector((state)=>state.groups)
  const {user} = useSelector((state)=>state.auth)
  const [isSettleFormVisible, setSettleFormVisibility] = useState(false)
  const [betWinner, setBetWinner] = useState('')
  const dispatch = useDispatch()

  const date = bet.by ? formatDate(bet.by): "no date set"

  //Create an array of userIds of both the bettors and bettees
  const participantUserIds = [bet.bettor.userId, ...bet.bettee.map(item => item.userId)]


  //Function that toggles the settle button
  const toggleSettle = () =>{
    setSettleFormVisibility(!isSettleFormVisible)
  }

  const handleSettleBet = (selectedWinner) => {
    if (!selectedWinner){
        toast.error('Please select a winner')
        return
    }
    
    const settleData = {betId:bet._id, winner:selectedWinner}

    //Acces the group members from the redux state
    const groupMembers = group ? group.members.map(member => ({...member})): []

    // Find the index of the groupmember who matches the bet winner userId
    const winnerIndex = groupMembers.findIndex((member) => member.userId === selectedWinner.userId)

    // If an index was found, update the winners' balance with each loser paying
    if(winnerIndex !== -1) {
      groupMembers[winnerIndex].balance += bet.bettee.length*bet.amount
    }

    // Update the loser's balances
    participantUserIds.forEach(userId => {
      if (userId!==selectedWinner.userId){
        const loserIndex = groupMembers.findIndex(member => member.userId === userId)
        if (loserIndex !== -1){
        // If an index was found, update the losers' balance
        groupMembers[loserIndex].balance -= bet.amount
        }
      }
    })

    dispatch(settleBet(settleData))
    dispatch(updateMemberBalances({groupId:group._id, members:groupMembers}))  

    setSettleFormVisibility(false)
 
  }

  return (
    <>
    <div className="form-group w-full p-1 border-b border-gray-300">
      {group.length === 0 ? (
        <>
          <label className="font-bold">{bet.group.groupName} </label>
        </>
      ):(<></>)}
      <div className="w-full mb-2 flex items-center justify-between text-sm">
          <label>{bet.bettor?.name} bets {bet.bettee?.map((item)=>item.name).join(', ')}, |${bet.amount}|, details: {bet.details}, by: {' '}{date}</label>
          {bet.settled ? (
          <></>
          ):(
            <>
              {!participantUserIds.includes(user._id) ? (
                <button className = "btn ml-1 mr-1" disabled >
                  Settle
                </button>
            ):(
              <button className = "btn ml-1 mr-1" onClick={toggleSettle}>
                Settle
              </button>
              )}
            </>
          )}
          {user._id === bet.bettor?.userId ? (
          <button onClick={() => dispatch(deleteBet(bet._id))} className="btn">
            <FaTrash/>
          </button>
            ) : (
            <button onClick={() => dispatch(deleteBet(bet._id))} disabled={true} className="btn bg-gray">
              <FaTrash/>
            </button>
          )}
          
      </div>
      {bet.settled ? (
        <>
          <label className="font-bold">{bet.winner.name} won</label>
        </>
      ):(<></>)}
      {isSettleFormVisible ? (
        <>
          <div className="w-full mb-2 flex items-center justify-between">
            <select
                className = "items-center"
                name = "selectWinner"
                id = "selectWinner"
                value={betWinner}
                onChange={(e) => setBetWinner(e.target.value)}
            >
                <option value="">Who won?</option>
                <option value={bet.bettor.name}>{bet.bettor.name}</option>
                {bet.bettee.map((item)=> (
                  <option key={item.userId} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </select>
            {/* Pass the userId and name of whoever is selected based on if  they are a bettor or a bettee */}
            <button 
              className = "btn ml-1 mr-1 mb-2" 
              onClick={() => {
                if (betWinner === bet.bettor.name) {
                  handleSettleBet({ userId: bet.bettor.userId, name: bet.bettor.name });
                } else {
                  const selectedBettee = bet.bettee.find((item) => item.name === betWinner);
                  if (selectedBettee) {
                    handleSettleBet({ userId: selectedBettee.userId, name: selectedBettee.name });
                  } else {
                    toast.error('Invalid winner selection');
                  }
                }
              }}
            >
              Confirm
            </button>
          </div>
        </>):(
          <></>
        )}
    </div>
    </>
  )
}

export default BetItem