import {useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { verifyResetToken, resetPassword, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import {toast} from 'react-toastify'

function PasswordReset () {
    const dispatch = useDispatch()
    const isError = useSelector(state => state.auth.isError)
    const errorMessage = useSelector(state => state.auth.message)
    const location = useLocation()
    const navigate = useNavigate()
    const [isSuccess, setIsSuccess] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [newPassword, setNewPassword] = useState({
        password: '',
        confirmPassword: ''
    })

    const {token, id} = queryString.parse(location.search)
    
    const verifyToken = async () => {
        const data = {token:token, id:id}
        await dispatch(verifyResetToken(data))
        setIsLoading(false)
    }

    const handleOnChange = ({target}) => {
        const {name, value} = target

        setNewPassword({...newPassword, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        
        const {password, confirmPassword}= newPassword

        let hasError = false
                
        if(password.trim().length < 8 || password.trim().length > 20){
            toast.error('Password must be 8 to 20 characters long')
            hasError = true
            setIsLoading('true')
        }
        if(password !== confirmPassword){
            toast.error('Passwords do not match')
            hasError = true
            setIsLoading(true)
        }
        if(!hasError){
        const data = {token:token, id:id, password:confirmPassword}
        await dispatch(resetPassword(data))
        setIsSuccess(true)
        }
    }
    
    // Navigate to Login Page after succesful password change
    const handleLogin = (e) => {
        e.preventDefault()
        navigate('/login') 
    }

    useEffect(() => {
        verifyToken()
    },[isError, isLoading])

    // Render Spinner if isLoading
    if(isLoading){
        return <Spinner />
    }
    
    // Render error if isError
    if(isError === true){
        return (
            <>
                <section className="heading">
                <h1 className="text-center">
                Reset Password
                </h1>
                </section>
                <section className="heading form shadow-md p-4">
                    <div className="form-group p-4">
                        <p className="text-center">
                        Error: {errorMessage}
                        </p> 
                        <button 
                            type="submit"
                            onClick={() => {
                                setIsSuccess(false)
                                setIsLoading(true)
                                dispatch(reset())}} 
                            className="mt-4 btn btn-block bg-black" 
                            > Back
                        </button>
                    </div>
                </section>
            </>
        )
    }
    
    // Render the password reset successfully if isSuccess
    if(isSuccess === true){
        navigate('/reset-password')
        return ( 
            <>
                <section className="heading">
                <h1 className="text-center">
                Reset Password
                </h1>
                </section>
                <section className="heading form shadow-md p-4">
                    <div className="form-group p-4">
                        <p className="text-center">
                        Password Reset Successfuly 
                        </p>
                        <button 
                                type="submit"
                                onClick={handleLogin} 
                                className="mt-4 btn btn-block bg-black" 
                                > Login
                        </button>
                    </div> 
                </section>
            </>
        )
    }

    // Render Password reset form as default
    return (
        <>
            <section className="heading">
                <h1 className="text-center">
                Reset Password
                </h1>
            </section>
            <section className="form shadow-md p-4">
                <form onSubmit={handleSubmit} className="space-y-8 justify-between items-center">
                    <div className="form-group">
                        <input 
                            type="password"
                            className="form-control"  
                            placeholder="********"
                            name='password'
                            onChange = {handleOnChange}
                            // className="px-3 text-lg w-full border-gray-500 border-2 rounded" 
                        />
                        <input 
                            type="password"
                            className="form-control" 
                            placeholder="********" 
                            name='confirmPassword'
                            onChange = {handleOnChange}
                            // className="px-3 text-lg w-full border-gray-500 border-2 rounded" 
                        />
                        <button 
                            type="submit" 
                            className="btn btn-block bg-black" 
                        > Reset Password </button>
                    </div>
                </form>
            </section>
        </>
    )
}

export default PasswordReset