import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {toast} from 'react-toastify'
import betService from './betService'


// Function that describes the initial state
const initialState = {
    bets: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: ''
}

// Create new bet
export const createBet = createAsyncThunk('bets/create', async (betData, thunkAPI) => {
    try {
        // Get the user token from the State using thunkAPI (stored in localStorage)
        const token =thunkAPI.getState().auth.user.token
        // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
        const response =  await betService.createBet(betData, token)
        
        toast.success('Your Bet was successfuly created')
        return response
        
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        toast.error(message)
        return thunkAPI.rejectWithValue(message)
    }
})

// Get user's bets
export const getUserBets = createAsyncThunk('bets/getUserBets', async (_, thunkAPI) => {
    try {
        // Get the user token from the State using thunkAPI (stored in localStorage)
        const token =thunkAPI.getState().auth.user.token
        // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
        return await betService.getUserBets(token)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

// Get a Group's bets
export const getGroupBets = createAsyncThunk('bets/getGroupBets', async (groupId, thunkAPI) => {
    try {
        
        return await betService.getGroupBets(groupId)
    
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
        return thunkAPI.rejectWithValue(message) 
    }
})

// Settle a bet
export const settleBet = createAsyncThunk('bets/settleBet', async (settleData, thunkAPI) => {
    try {
        // Get the user token from the State using thunkAPI (stored in localStorage)
        const token =thunkAPI.getState().auth.user.token
        // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
        const response = await betService.settleBet(settleData,token)

        toast.success("Your bet was succesfully settled")
        return response
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
            toast.error(message)
        return thunkAPI.rejectWithValue(message) 
    }
})

// Delete user's bets
export const deleteBet = createAsyncThunk('bets/delete', async (id, thunkAPI) => {
    try {
        // Get the user token from the State using thunkAPI (stored in localStorage)
        const token =thunkAPI.getState().auth.user.token
        // Call the createBet function in the betService and pass in the and the user id that has been stored in localStorage
        return await betService.deleteBet(id, token)
    } catch (error) {
        const message = 
            (error.response && 
                error.response.data && 
                error.response.data.message) || 
            error.message || 
            error.toString()
            toast.error(message)
        return thunkAPI.rejectWithValue(message) 
    }
})

// The betSlice
export const betSlice = createSlice({
    name: 'bet',
    initialState,
    reducers: {
        reset: (state) => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(createBet.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createBet.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bets.push(action.payload)
            })
            .addCase(createBet.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getUserBets.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUserBets.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bets =action.payload
            })
            .addCase(getUserBets.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getGroupBets.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getGroupBets.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bets = action.payload
            })
            .addCase(getGroupBets.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(settleBet.pending, (state) => {
                state.isLoading = true
            })
            .addCase(settleBet.fulfilled, (state, action) => {
                const updatedBet = action.payload
                const index = state.bets.findIndex(bet => bet._id === updatedBet._id)

                if (index !== -1) {
                    state.bets[index] = updatedBet
                }
                state.isLoading = false
                state.isSuccess = true
            })
            .addCase(settleBet.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = state.bets
            })
            .addCase(deleteBet.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteBet.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.bets = state.bets.filter(
                    (bet) => bet._id !== action.payload.id
                    )
            })
            .addCase(deleteBet.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const {reset} = betSlice.actions
export default betSlice.reducer