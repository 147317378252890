import {useState} from 'react'

function GroupMember({member}) {
  // Hover overlay
  const [showOverlay, setShowOverlay] = useState(false)
  // Function to toggle overlay visibility for mobile
  const toggleOverlay = () => {
    setShowOverlay(!showOverlay)
  }
  

  return (
    <div
    className={`relative form-group p-1 ml-1 mr-1 flex justify-between items-top border-b border-gray-300 transition-all duration-300 ${showOverlay ? 'h-20':'h-10'}`}
      // onMouseEnter={() => setShowOverlay(true)}
      // onMouseLeave={() => setShowOverlay(false)}
      onClick={toggleOverlay} // Mobile click handler
    >
        <h2 className="font-bold mb-2">{member.name}</h2>
        {member.balance < 0 ? (
        <h2 className="font-bold mb-2 mr-1">Balance: -${Math.abs(member.balance)}</h2>
        ):(
        <h2 className="font-bold mb-2 mr-1">Balance: ${member.balance}</h2>
        )}
      {showOverlay && (
        <div
          className="mt-2 absolute top-0 left-1 w-full h-full bg-opacity-50 flex items-center justify-left"
          // style={{ zIndex:10 }}
        >
          <p>{member.email}</p>
        </div>
      )}
    </div>
  )
}

export default GroupMember