import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import betReducer from '../features/bets/betSlice'
import involvedReducer from '../features/bets/involvedSlice'
import groupReducer from '../features/groups/groupSlice'
import userReducer from '../features/users/userSlice'
import IOUReducer from '../features/bets/IOUSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    bets: betReducer,
    involved: involvedReducer,
    groups: groupReducer,
    users: userReducer,
    IOUs: IOUReducer,
  },
})
