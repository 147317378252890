import {useState, useEffect} from 'react'
import {FaSignInAlt} from 'react-icons/fa'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import queryString from 'query-string'
import {toast} from 'react-toastify'
import {login, reset} from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function Login() {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })

    const {email, password} = formData

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    
    // Check the URL to see if there is a betId and memberId
    const {betId, memberId, declined} = queryString.parse(location.search)

    // Destructure the useSelector to manage the different states
    const {user, isLoading, isError, isSuccess, message} = useSelector
    (
        (state) => state.auth
    )

    // The use effect function
    useEffect(()=>{
        if(isError) {
            toast.error(message)
        }
        if(isSuccess || user){
            // If there is a betId and memberId in the URL navigate to that bet
            if(betId && memberId){
                if(declined===true){
                    navigate(`/decline?betId=${betId}&memberId=${memberId}`)    
                }else{
                    navigate(`/accept?betId=${betId}&memberId=${memberId}`)
                }
            }else{
                navigate('/')
            }
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange=(e) =>{
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const forgotPassword = (e) => {
        e.preventDefault()

        navigate('/forgot-password')        
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const userData = {
            email,
            password
        }

        dispatch(login(userData))
    }

    if(isLoading){
        return <Spinner />
    }
  
    return (
    <>
    <section className="heading">
        <h1 className ="flex items-center justify-center">
            <span className="pr-2">
                <FaSignInAlt/>
            </span> 
            Login           
        </h1>
        <p className="text-2xl">Login and start betting between friends</p>
    </section>
    <section className="form">
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <input 
                type="email" 
                className="form-control" 
                id="email" 
                name="email" 
                value={email} 
                placeholder='Enter your email' 
                onChange={onChange} 
                />
            </div> 
            <div className="form-group">
                <input 
                type="password" 
                className="form-control" 
                id="password" 
                name="password" 
                value={password} 
                placeholder='Enter password' 
                onChange={onChange} 
                />
            </div>
            <div className="form-group">
                    <span 
                        className = "clickable-span"
                        onClick = {forgotPassword}
                    > 
                    Forgot password?  
                    </span>  
            </div>   
            <div className="form-group">
                <button type="submit" className="btn btn-block bg-black">
                    Submit
                </button>
            </div>
        </form>
    </section>
    </>
    )
}

export default Login