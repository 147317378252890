import {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {toast} from 'react-toastify'
import {FaUser} from 'react-icons/fa'
import {register, reset} from '../features/auth/authSlice'
import { addGroupToUser } from '../features/users/userSlice'
import {addMemberToGroup} from '../features/groups/groupSlice'
import queryString from 'query-string'
import Spinner from '../components/Spinner'

function Register() {
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    
    // Set the form data to empty strings
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password2: '',
    })

    const {email: userEmail, groupId, groupName} = queryString.parse(location.search)

    // Destructure the form Data to have the following variables
    const {name, email, password, password2} = formData
    
    // Destructure the useSelector to manage the different states
    const {user, isLoading, isError, isSuccess, message} = useSelector
    (
        (state) => state.auth
    )

    // The use effect function
    useEffect(()=>{
        if(isError) {
            toast.error(message)
        }
        if(user != null && user.verified === false){
            navigate('/verify')
        }
        if(userEmail){
            setFormData((prevState) => ({ ...prevState, email: userEmail}))
        }

        dispatch(reset())

    }, [user, isError, isSuccess, message, navigate, dispatch, userEmail])
    
    // Function that is called when someone makes a change to the form
    const onChange=(e) =>{
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }
    
    // Function that is called when the user clicks the Submit button
    const onSubmit = async (e) => {
        e.preventDefault()

        if(password !== password2) {
            toast.error('Passwords do not match')
        } else {
            const userData = {
                name,
                email,
                password,
            }

            await dispatch(register(userData))

            if(groupId){

                const user = JSON.parse(localStorage.getItem('user'))

                if(!user){
                    toast.error('You have not been successfuly added to the group. Ask the person who invited you to invite you again')
                } else{

                const groupInfo = {_id: groupId, groupName: groupName}
                const userId = user._id
                const userLookupInfo = {
                    _id: userId,
                    name: user.name,
                    email: user.email,
                    notifications: user.notifications
                }

                await dispatch(addMemberToGroup({groupId, userLookupInfo}))

                await dispatch(addGroupToUser({userId, groupInfo}))

                }
            }
        }
    }
    
    // If the page is loading, return the spinner.
    if(isLoading){
        return<Spinner/>
    }

    // Show the frontend html code that renders the Register page  
    return (
    <>
    <section className="heading">
        <h1 className ="flex items-center justify-center">
            <span className="pr-2">
                <FaUser/>
            </span> 
            Register            
        </h1>
        <p className="text-2xl">Please Create an Account</p>
    </section>
    <section className="form">
        <form onSubmit={onSubmit}>
            <div className="form-group">
                <input 
                type="text" 
                className="form-control" 
                id="name" 
                name="name" 
                value={name} 
                placeholder='Enter your name' 
                onChange={onChange} 
                />
            </div>
            <div className="form-group">
                <input 
                type="email" 
                className="form-control" 
                id="email" 
                name="email" 
                value={email} 
                placeholder='Enter your email' 
                onChange={onChange} 
                />
            </div> 
            <div className="form-group">
                <input 
                type="password" 
                className="form-control" 
                id="password" 
                name="password" 
                value={password} 
                placeholder='Enter password' 
                onChange={onChange} 
                />
            </div>   
            <div className="form-group">
                <input 
                type="password" 
                className="form-control" 
                id="password2" 
                name="password2" 
                value={password2} 
                placeholder='Confirm password' 
                onChange={onChange} 
                />
            </div>
            <div className="form-group">
                <button type="submit" className="btn btn-block bg-black">
                    Submit
                </button>
            </div>
        </form>
    </section>
    </>
    )
}

export default Register