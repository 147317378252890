import {useState, useEffect, useRef} from 'react'
import {FaEnvelopeOpen} from 'react-icons/fa'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {verifyEmail, generateNewOTP} from '../features/auth/authSlice'
import Spinner from '../components/Spinner'

function VerifyEmail() {
    const [otp, setOTP] = useState(['','','','','',''])
    const inputRefs = useRef([])

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Destructure the useSelector to manage the different states
    const {user, isLoading, isError, isSuccess, message} = useSelector
    (
        (state) => state.auth
    )

    // The use effect function
    useEffect(()=>{
        if(isError) {
            toast.error(message)
        }
        if(user != null && user.verified === true){
            toast.success('Email verified Succesfully')           
            navigate('/')
        }
        
        setOTP(['','','','','',''])

    }, [user, isError, isSuccess, message, navigate, dispatch])

    const onChange=(index, e) =>{
        const value = e.target.value
        if (value.length === 1 && /^[0-9]*$/.test(value)){
            setOTP((prevOTP) => {
                const newOTP = [...prevOTP]
                newOTP[index] = value
                return newOTP
            })
            if(index < inputRefs.current.length -1){
                inputRefs.current[index+1].focus()
            }
        } else if (value.length === 0){
            setOTP((prevOTP) => {
                const newOTP = [...prevOTP]
                newOTP[index]=''
                return newOTP
            })
            if(index > 0){
                inputRefs.current[index - 1].focus()
            }
        }
              
    }

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && !otp[index]) {
          inputRefs.current[index - 1].focus();
        }
    }

    const sendNewOTP = (e) => {
        e.preventDefault()

        let thisUser

        if(user){
            thisUser = user
        }else{
            thisUser = JSON.parse(localStorage.getItem('user'))
        }
        if(thisUser){
            const userData = {
                userId: thisUser._id,
                email: thisUser.email
            }
            dispatch(generateNewOTP(userData))
            toast.success(`A new One Time Passcode has been emailed to ${thisUser.email}`)
        }
        if(!thisUser){
            toast.error('No user identified, redirecting')
            navigate('/')
        }
        
        setOTP(['','','','','',''])
    }

    const onSubmit = (e) => {
        e.preventDefault()

        const otpString = otp.join('')

        if(user != null){
            const data = {
                userId: user._id,
                otp: otpString
            }

            dispatch(verifyEmail(data))
        }else{
            const userData = JSON.parse(localStorage.getItem('user'))
            if(!userData){
                toast.error('No user identified, redirecting')
                navigate('/')
            }else{
                const data = {
                    userId: userData._id,
                    otp: otpString
                }

                dispatch(verifyEmail(data))
            }

        }
        setOTP(['','','','','',''])
    }

    if(isLoading){
        return <Spinner />
    }
  
    return (
    <>
    <section className="heading">
        <h1 className ="flex items-center justify-center">
            <span className="pr-2">
                <FaEnvelopeOpen/>
            </span> 
            Email Verification         
        </h1>
        <p className="text-2xl">A One-time passcode has been sent to your email</p>
    </section>
    <section className="form">
    <h1 className="text-2xl mb-6">Please enter your passcode below:</h1>
        <form onSubmit={onSubmit}>
            <div className="flex justify-center items-center">
                {otp.map((digit, index) => (
                    <input 
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text" 
                    className="border border-gray-300 rounded-lg w-12 h-12 text-center mx-2" 
                    maxLength={1}
                    value={digit} 
                    onChange={(e) => onChange(index,e)}
                    onKeyDown={(e) => handleBackspace(index,e)} 
                    />
                ))}   
            </div>  
            <div className="form-group mt-6 flex justify-center items-center">
                <button type="submit" className="btn w-[50%] bg-black">
                    Submit
                </button>
            </div>
        </form>
    </section>
    <section>
        <p>Didn't receive a passcode?</p>
        <p>
            Check your spam folder, or  <span 
                className = "clickable-span"
                onClick = {sendNewOTP}
            > 
            click here
            </span>
        </p>        
        <p>to receive a new one</p>
        
    </section>
    </>
    )
}

export default VerifyEmail