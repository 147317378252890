import { useState } from "react"
import { useDispatch, useSelector} from "react-redux"
import {toast} from 'react-toastify'
import { createGroup, addMemberToGroup } from "../features/groups/groupSlice"
import { getUserId, addGroupToUser} from "../features/users/userSlice"
import {updateUser} from '../features/auth/authSlice'

function GroupForm({isGroupFormVisible, toggleVisibility}) {
    const dispatch = useDispatch()
    // Initialize the local state variables
    const [groupName, setGroupName] = useState('')
    const [email, userEmail] = useState('')
    // Initialize the global state variables
    const { user } = useSelector((state)=>state.auth)
    const { group } = useSelector((state)=>state.groups)
    // Check to see if we are in the create group or the add user mode
    const isCreateGroup = group.length === 0
      

    const onSubmit = async (e) => {
        e.preventDefault()
        
            if(isCreateGroup){
            // Check the logged in user's groups to see if they are already part of a group with the given groupName
            let alreadyGroup
            
            // Check to see if the user is part of any group
            if(Array.isArray(user.groups)){
                alreadyGroup = user.groups.some((group) => group.groupName.toLowerCase() === groupName.toLowerCase())
            }
            
            // If the logged in user already belongs to a group with this name, throw an error, else push the user email to the existing group
            if(alreadyGroup){
                toast.error('You are already part of a group with this name')
            }else{               
                // Dispatch the createGroup function to create the group using the entered group name
                await dispatch(createGroup({groupName, user}))

                // Retrieve the newly created groups' id from the  
                const groupInfo = JSON.parse(localStorage.getItem('groupInfo'))
                
                const userId = user._id

                // Add the group name to the user as well
                await dispatch(addGroupToUser({userId, groupInfo}))

                // Update the user state
                await dispatch(updateUser(user))
            }
        }else{ // Execute this code if you are adding a user to an existing group

            // Lookup the group info that is in the local storage
            const groupInfo = JSON.parse(localStorage.getItem('groupInfo'))
            
            // Search for the user email in the DB and get the userId and name to store in local storage
            const info = {
                email: email,
                inviteUserName: user.name,
                inviteEmail: user.email,
                groupId: groupInfo._id,
            }

            await dispatch(getUserId(info))

            // Retrieve the information of the looked up user from the local storage
            const userLookupInfo = JSON.parse(localStorage.getItem('userLookupInfo'))
            
            
            // If the looked up user exists check to see that they haven't been added to the group already
            if(userLookupInfo){
                // Check the current group members to make sure this person hasn't been added to the group already
                const alreadyUser = group.members.some((member) => member.userId === userLookupInfo._id) 
                // If they are throw an error, if not, continue to the following steps
                if(alreadyUser){
                    toast.error('This user has already been added to the group')
                }else{
                    const groupId = groupInfo._id
                    
                    // Dispatch the addMember to Group function and pass in the userId                
                    await dispatch(addMemberToGroup({groupId, userLookupInfo}))

                    const userId = userLookupInfo._id

                    await dispatch(addGroupToUser({userId, groupInfo}))
                }
            }
        }       
        // Clear the form by setting all of the states to empty strings
        setGroupName('')
        userEmail('')

        // Hide the group form once submitted if it is visible
        if( isGroupFormVisible){
        toggleVisibility()
        }
    }

    return (
    <form onSubmit={onSubmit}>
        <div className="form-group">
            {group.length === 0 ? (
                <>
                    <div className="pt-3 flex items-center border-b border-t border-gray-300">
                        <label htmlFor="text">Group Name</label>
                        <input 
                        type="text" 
                        name='groupName' 
                        id='groupName' 
                        value={groupName} 
                        onChange={(e)=>setGroupName(e.target.value)} 
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="pt-3 flex items-center border-b border-t border-gray-300">
                        <label htmlFor="text">Email:</label>
                        <input 
                        type="email" 
                        name='userEmail' 
                        id='userEmail' 
                        value={email} 
                        onChange={(e)=>userEmail(e.target.value)}
                        className = "ml-4" 
                        />
                    </div>
                </>
            )}
        </div>
        <div className="form-group pt-4">
            <button className="btn btn-block bg-black" type="submit">
                {isCreateGroup ? "Create":"Add member"}
            </button>
        </div>
    </form>
    )
}

export default GroupForm