import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {toast} from 'react-toastify'
import { updateUser } from '../features/auth/authSlice'
import { createIOU } from '../features/bets/IOUSlice'
import { settleBet } from '../features/bets/betSlice'
import {getGroup, updateMemberBalances} from '../features/groups/groupSlice'
import { updateUserGroupBalance } from '../features/users/userSlice'


function IOUForm() {

    // Initialize the global state variables
    const { group } = useSelector((state) => state.groups)
    const { user } = useSelector((state) => state.auth)

    // Initialize the local state variables
    const [isIOUFormVisible, setIOUFormVisibility] = useState(false)
    const [debtor, setSelectedDebtor] = useState(null)
    const [debtee, setSelectedDebtees] = useState([])
    const [amount, setAmount] = useState()
    const [details, setDetails] = useState('')

    // Initialize the bettor as the username
    const bettor = { userId: user._id, name: user.name, email: user.email }

    const today = new Date()


    // Initialise the useDispatch function as variable dispatch
    const dispatch = useDispatch()

    const toggleDebteeSelection = (member) => {
        if (debtee.some((item) => item.userId === member.userId)) {
            // If the member is alreay in debtee, remove item
            setSelectedDebtees(debtee.filter((item) => item.userId !== member.userId))
        } else {
            setSelectedDebtees([...debtee, { userId: member.userId, name: member.name, email:member.email, status:'accepted' }])
        }
    }

    //Function that toggles the add bet form when button is pressed
    const toggleIOUForm = () => {
        setIOUFormVisibility(!isIOUFormVisible)
    }

    const handleSettleBet = async (selectedWinner, thisIOU) => {
        if (!selectedWinner){
            toast.error('Please select a winner')
            return
        }
        if(!debtor){
            toast.error('Please select a the person that is owed money')
        }
        if(debtee.length === 0){
            toast.error('Please select at least one person who owes money')
        }

        //Create an array of participants in the bet by combinging both the bettors and bettees 
        const participants = [debtor, ...debtee]
    
        // Create an empty array to store the participants in the bet who have an accepted or closed status
        let acceptedParticipantUserIds = []
    
        // Fill the accepted participants array by cycling through the participants and checking their status
        participants.forEach(participant => {
            if(participant.status === 'accepted' || participant.status === 'closed'){
                acceptedParticipantUserIds.push(participant.userId)
            } 
        })

        // Push the debtor userId to the accepted acceptedParticipantsUserId array
        acceptedParticipantUserIds.push(debtor)
      
        // Define the settleData as the bet Id and the selected winner
        const settleData = {betId:thisIOU._id, winner:selectedWinner}
    
        //Create a mutable groupMembers array. 
        let groupMembers
    
        if (group.members) {
            // If the group exists, map members to groupMembers
            groupMembers = group.members.map(member => ({ ...member }));
        } else {
            // If the group doesn't exist, dispatch an action to get it
            await dispatch(getGroup(thisIOU.group.groupId))
                .then((action) => {
                    // Once the group is retrieved, you can access members
                    const retrievedGroup = action.payload;
                    groupMembers = retrievedGroup.members.map(member => ({ ...member }));
                })
                .catch((error) => {
                    // Handle error if needed
                    console.error('Error fetching group:', error);
                });
        }
    
        // Find the index of the groupmember who matches the bet winner userId
        const winnerIndex = groupMembers.findIndex((member) => member.userId === selectedWinner.userId)
        
        // If an index was found, update the winners' balance with each loser paying
        if(winnerIndex !== -1) {
          groupMembers[winnerIndex].balance += (acceptedParticipantUserIds.length-1)*thisIOU.amount
        }
    
        // Update the loser's balances
        acceptedParticipantUserIds.forEach(userId => {
          if (userId!==selectedWinner.userId){
            const loserIndex = groupMembers.findIndex(member => member.userId === userId)
            if (loserIndex !== -1){
            // If an index was found, update the losers' balance
            groupMembers[loserIndex].balance -= thisIOU.amount
            }
          }
        })
    
        // Update each of the members balances in their user group. 
        const updateMemberUserBalances = groupMembers.map(async (member)=>{
            const memberGroupInfo = {userId:member.userId, groupId:thisIOU.group.groupId, balance:member.balance}
    
            await dispatch(updateUserGroupBalance(memberGroupInfo))
        })
    
        await dispatch(settleBet(settleData))
        await dispatch(updateMemberBalances({groupId: thisIOU.group.groupId, members:groupMembers}))  
        await Promise.all(updateMemberUserBalances)
        await dispatch(updateUser())
      }

    useEffect(()=>{
        if(debtor){
            setSelectedDebtees(debtee.filter((item) => item.userId !== debtor.userId))
        }

    },[bettor])

    const onSubmit = async (e) => {
        e.preventDefault()

        const groupInfo = { groupId: group._id, groupName: group.groupName, members: group.members }
        
      
        // Dispatch the createBet function and pass in the bet information as a single object
        const actionResult = await dispatch(createIOU({ groupInfo, bettor, bettee:debtee, amount, details, date:today, winner:debtor}))
        
        // Ensure the IOU was created successfully
        if (actionResult.meta.requestStatus === 'fulfilled') {
            const thisIOU = actionResult.payload // This should contain the created IOU
            
            const selectedWinner = debtor
            await handleSettleBet(selectedWinner, thisIOU)
            
            // Clear the form by setting all of the states to empty strings
            setSelectedDebtees([])
            setSelectedDebtor(null)
            setAmount(0)
            setDetails('')
            toggleIOUForm()
        } else {
            toast.error('Failed to create IOU. Please try again.')
        }
    }


    return (
        <section>
            <button
                className="btn btn-block bg-black"
                onClick={toggleIOUForm}
            >Create an IOU</button>
            {isIOUFormVisible ? (
                <form onSubmit={onSubmit}>
                    <h1 className="text-2xl">IOU Details</h1>
                    <h3>Use this form to track IOUs from past bets or when Splitting a bill</h3>
                    <div className="form-group">
                        <div className="pt-4 border-b border-gray-300">
                            <label htmlFor="text">Select the people who owe money</label>
                            <ul className="list-disc ml-4 mr-4 pb-4">
                                {group.members.length === 1 ? (
                                    <input
                                    type="text"
                                    name='bettor'
                                    id='bettor'
                                    value="Please add/invite members to the group who owe you"
                                    className="ml-4"
                                    disabled
                                    />
                                    ) : (
                                        group.members.map((member) => {
                                            if(debtor){
                                                if (member.userId === debtor.userId) {
                                                    return null
                                                }
                                            }
                                            return (
                                                <li key={member.userId}
                                                    className="py-1 border-b border-gray-300 flex items-center"
                                                >
                                                    <input
                                                        type="checkbox"
                                                        checked={debtee.some((item) => item.userId === member.userId)}
                                                        onChange={() => toggleDebteeSelection(member)}

                                                    />
                                                    <span className="ml-4">{member.name}</span>
                                                </li>
                                            )
                                        })
                                    )
                                }
                            </ul>
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="text">Owes:</label>
                            <select 
                                name="debtor"
                                id = "debtor"
                                values={debtor ? debtor.name: ''}
                                onChange = {(e) => {
                                    const selectedMember = group.members.find(member => member.userId === e.target.value);
                                    setSelectedDebtor(selectedMember)
                                }}                       
                                className="ml-4"
                                >
                                    <option value="">Select a member</option>
                                {group.members.map((member) => (
                                    <option key={member.userId} value={member.userId}>
                                        {member.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="number">Amount:</label>
                            <input
                                type="number"
                                name='amount'
                                id='amount'
                                value={amount === 0 ? '' : amount}
                                onChange={(e) => {
                                    const inputValue = parseInt(e.target.value);
                                    if (!isNaN(inputValue)) {
                                        setAmount(Math.max(0, inputValue));
                                    } else {
                                        // Handle invalid input, e.g., non-numeric characters
                                        setAmount('');
                                    }
                                }}
                                min='0'
                                className="ml-4"
                            /><label className="p-2">.00</label>
                        </div>
                        <div className="pt-4 flex items-center border-b border-gray-300">
                            <label htmlFor="text">For (Details):</label>
                            <input
                                type="text"
                                name='details'
                                id='details'
                                value={details}
                                onChange={(e) => setDetails(e.target.value)}
                                className="ml-4"
                            />
                        </div>
                    </div>
                    <div className="pt-4 form-group">
                        <button className="btn btn-block bg-black" type="submit">Submit</button>
                    </div>
                </form>) : (<></>)}
        </section>
    )
}

export default IOUForm